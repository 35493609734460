@import '../../styles/customMediaQueries.css';

.root {
    position: relative;
    display: inline-block!important;

    @media (--viewportLarge) {
      display: none!important;
    }
}

.modalOpener {
    composes: button buttonBordersSecondary buttonColorsSecondary from global;

    padding: var(--marketplaceButtonSmallDesktopPadding);
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}
  
.modalOpenerSelected {
    composes: button buttonBorders buttonColors from global;

    padding: var(--marketplaceButtonSmallDesktopPadding);
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.threeBarsIcon {
    width: 16px;
    height: 16px;
}

.modalLayer {
    width: 100vw;
    height: 100%;
    background-color: rgba(21, 21, 28, 0.42);
}

.modalContainer {
    max-width: 420px;
    width: 100%;
    height: 100%;
    background-color: var(--matterColorLight);
    position: absolute;
    top: 0;

    @media (--viewportMedium) {
        max-width: 375px;
        width: 375px;
    }
}

.modalTitle {
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    padding-left: 24px;
    border-bottom: 1px solid #EEEFEF;
}

.modalClose {
    right: 29px;
    top: 21px;
    padding: 0;
    background: none;
    position: absolute;

    > svg {
      width: 13px;
      height: 13px;
    }
}

.modalContent {
    height: calc(100vh - 112px);
    overflow-y: auto;

    & > form > div:last-child {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.filterContainer {
    padding: 24px 24px 30px 24px;

    &:nth-child(n+2) {
        border-top: 1px solid #EEEFEF;
    }
}

.filterTitle {
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    color: var(--marketplaceColorDark);
    padding-bottom: 6px;
}

.filterItem {
    height: 40px;
}