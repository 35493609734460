@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 2;
  }
}

.layoutContainer {
  width: 100%;
  position: relative;
  padding-top: calc(var(--topbarHeightDesktop) + 56px);

  @media (--viewportLarge) {
    padding-top: calc(var(--topbarHeightDesktop) + 68px);
  }
}

.container {
  composes: layoutContainer;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  width: 100%;
  position: fixed;
  top: 64px;
  left: 0;
  background-color: var(--matterColorLight);
  z-index: 1;
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    top: 68px;
  }

  @media (--viewportLarge) {
    height: 68px;
    top: 68px;
  }
}

.searchFiltersPrimary {
  display: flex;
  padding: 0 24px;
}

.searchFiltersMobile {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  background-color: var(--matterColorLight);
  height: 100vh;
}

.searchListingsPanelWithoutMap {
  width: 100%;
  background-color: var(--matterColorLight);
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  composes: button marketplaceTinyFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportLarge) {
    width: 45vw;
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: calc(var(--topbarHeightDesktop) + 68px);
    right: 0;

    /* Fixed content needs width relative to viewport */
    height: calc(100vh - var(--topbarHeightDesktop) - 68px);
  }
}
